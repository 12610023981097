@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Raleway", sans-serif;
  background-color: #edede9;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  color: white !important;
}

@layer components {
  .section-title {
    font-size: 22px;
    margin-bottom: 16px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .section-text {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .contact-form-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
  }

  .contact-form-field:last-of-type {
    margin-bottom: 16px;
  }

  .contact-form-field input,
  .contact-form-field textarea {
    padding: 6px 20px;
  }

  .contact-form-field span {
    padding: 4px 0;
  }

  .project-card h3 {
    text-transform: uppercase;
  }

  @media (min-width: 768px) {
    .section-title {
      font-size: 24px;
      margin-bottom: 20px;
    }

    .section-text {
      font-size: 18px;
      line-height: 1.85em;
    }

    .contact-form-field span {
      font-size: 18px;
      line-height: 28px;
    }
  }

  @media (min-width: 1024px) {
    .project-card {
      position: relative;
    }

    .project-card h3 {
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      cursor: pointer;
      width: 100%;
      height: 100%;
      color: white;
      background-color: rgba(0, 0, 0, 0.4);
      transition: 0.3s linear;
      text-shadow: 0px 0px 40px rgba(0, 0, 0, 1);
    }

    .project-card:hover h3 {
      opacity: 100%;
    }

    .project-card:hover .project-card-img {
      transform: scale(1.05);
    }

    .section-title {
      margin-bottom: 24px;
    }
  }
}
